import { type NavbarConfigFragment } from "@gql-schema";
import { useActiveMenuItemIndex } from "@shared/components/Navigation/use-active-menu-item-index";
import { useLoggedProfile } from "@shared/hooks/useLoggedProfile";
import { NavBarContainerDesktop } from "@ui/components/NavBar/styles/NavBarContainerDesktop";
import { NavBarContainerMobile } from "@ui/components/NavBar/styles/NavBarContainerMobile";
import { SiteLogo } from "@ui/components/SiteLogo/SiteLogo";
import { SiteLogoIcon } from "@ui/components/SiteLogo/SiteLogoIcon";
import { COLORS } from "@ui/theme/colors";
import { cx } from "@uxf/core/utils/cx";
import { memo } from "react";
import { BurgerMenu } from "./elements/BurgerMenu";
import { BurgerMobileMenu } from "./elements/BurgerMobileMenu";
import { DesktopUserMenu } from "./elements/DesktopUserMenu";
import { DesktopLandingPageMenu } from "./elements/desktop-landing-page-menu";

interface Props {
    absolute?: boolean;
    navbarData: NavbarConfigFragment;
}

const LOGO_ROUTE = "index";

export const GreenNavBar = memo<Props>((props) => {
    const { absolute, navbarData } = props;

    const profile = useLoggedProfile();

    const activeMenuItemIndex = useActiveMenuItemIndex(navbarData);

    return (
        <nav className={cx(absolute ? "transparent absolute z-fixed" : "bg-green", "w-full")}>
            <NavBarContainerMobile>
                <div className="w-full" />
                <div className="flex shrink-0">
                    <SiteLogoIcon $focusColor={COLORS.white} route={LOGO_ROUTE} title="Kalkulátor.cz" />
                </div>
                <div className="flex w-full justify-end">
                    <BurgerMobileMenu
                        $mr={24}
                        activeMenuItemIndex={activeMenuItemIndex}
                        logoRoute={LOGO_ROUTE}
                        profile={profile}
                        variant="white"
                        navbarData={navbarData}
                    />
                </div>
            </NavBarContainerMobile>
            <NavBarContainerDesktop>
                <div className="flex shrink-0">
                    <SiteLogo $focusColor={COLORS.white} route={LOGO_ROUTE} title="Kalkulátor.cz" variant="white" />
                </div>
                <div className="flex shrink-0 xl:hidden">
                    <BurgerMenu
                        activeMenuItemIndex={activeMenuItemIndex}
                        profile={profile}
                        variant="white"
                        navbarData={navbarData}
                    />
                </div>
                <div className="hidden h-full items-center xl:flex">
                    <DesktopLandingPageMenu
                        navbarData={navbarData}
                        activeMenuItemIndex={activeMenuItemIndex}
                        variant="white"
                    />
                    <DesktopUserMenu profile={profile} variant="white" />
                </div>
            </NavBarContainerDesktop>
        </nav>
    );
});

GreenNavBar.displayName = "GreenNavBar";
